import React from 'react';
import Whatsapp from './components/Whatsapp/Whatsapp';
import Home from './pages/Home';
// import GlobalStyles from './GlobalStyles';

function App() {
  return (
    <>
      <Home />
      <Whatsapp />
    </>
  );
}

export default App;
