import styled from 'styled-components';

export const InfoContainer = styled.div`
  color: black;
  background: ${({ lightBg }) => (lightBg ? '#f0f0f0' : '#fff')};
  padding-bottom: 50px;

  @media screen and (max-width: 960px) {
    padding: 50px 0;
    padding-bottom: 25x;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 960px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col2 col2' 'col1 col1'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 100px;
  /* padding-bottom: 100px; */

  @media screen and (max-width: 960px) {
    padding-top: 0px;
  }
`;

export const TopLine = styled.p`
  color: black;
  font-size: 16px;
  line-height: 5px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.3;
  font-weight: 600;
  color: black;
  margin-left: 45px;

  /* @media screen and (max-width: 960px) {
    margin-left: 0px;
    text-align: center;
  } */

  @media screen and (max-width: 960px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 24px;
  color: #f40f40;

  /* @media screen and (max-width: 960px) {
    text-align: center;
    margin-left: 45px;
  } */

  /* @media screen and (max-width: 622px) {
    font-size: 16px;
    text-align: left;
    padding-left: -15px;
  } */

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  /* justify-content: center; */
`;

export const Img = styled.img`
  width: 95%;
  /* margin: 0 0 10px 0; */
  padding-right: 0;
`;
